<template>
  <div class="poll-shadow br_4 mb_20 bgr_white" v-if="!guestMode">
    <div class="row-flex d_f f_w_w poll-size 111111">
      <div v-if="formData" class="col-md-8 col-sm-12 col-xs-12">
        <div class="poll-wrap-l">
          <h4 v-if="formData.heading" class="fs_22 lh_28 fw_m mb_20">{{ formData.heading }}</h4>

          <div v-if="stat.level">
            <div class="fs_12 lh_16 def_gray tt_u mb_10">Вопрос {{ stat.level }} из {{ stat.levels }}</div>
            <div class="d_f row_flex poll-progress bgr_f5f5f5">
              <div
                  class="progress-bar progress-bar-success progress-bar-striped poll-col poll-progress-item bgr_gray active"
                  role="progressbar"
                  :aria-valuenow="stat.progress"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="{ width: stat.progress + '%' }"
              >
                <span class="sr-only">{{ stat.progress }}%</span>
              </div>
            </div>
          </div>

          <div v-if="formData.fields">
            <dynamic-form></dynamic-form>
          </div>
          <div v-else-if="formData.component">
            <component :is="formData.component"></component>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 pos_rel">
        <online-support></online-support>
      </div>
    </div>
  </div>
</template>

<script>
import store from './store';
import { mapGetters, mapState } from 'vuex';
import PriceForm from './components/PriceFormComponent';
import PriceFormRadio from './components/PriceFormRadioComponent';
import ContactForm from './components/ContactFormComponent';
import SendForm from './components/SendFormComponent';
import DynamicForm from './components/DynamicFormComponent';
import OnlineSupport from './components/OnlineSupportComponent';

export default {
  store,
  components: {
    'dynamic-form': DynamicForm,
    'online-support': OnlineSupport,
    'price-form': PriceForm,
    'price-form-radio': PriceFormRadio,
    'contact-form': ContactForm,
    'send-form': SendForm
  },
  props: {
    source: {
      type: String,
      required: true
    },
    stepPrice: {
      type: Boolean,
      default: true
    },
    stepContacts: {
      type: Boolean,
      default: true
    },
    defaultSteps: {
      type: Array,
      default: () => []
    },
    defaultParams: {
      type: [Object, Array],
      default: () => ({})
    },
    defaultLabel: {
      type: [Object, Array],
      default: () => ({})
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    quizStart(value) {
      if (value) {
        window.emit.emit('quiz-start');
      }
    },
    quizSent(value) {
      if (value) {
        window.emit.emit('quiz-sent');
      }
    },
    quizDone(value) {
      if (value) {
        window.emit.emit('quiz-done');
      }
    }
  },
  computed: {
    ...mapGetters({
      formData: 'getForm',
      stat: 'statSteps'
    }),
    ...mapState({
      loader: 'loader',
      steps: 'steps',
      form: 'form',
      error: 'error',
      success: 'success',
      quizStart: 'quizStart',
      quizSent: 'quizSent',
      quizDone: 'quizDone',
      guestMode: 'guestMode'
    })
  },
  async created() {
    await this.$store.dispatch('checkGuestMode');

    this.$store.commit('setForm', this.defaultParams);
    this.$store.commit('setMobile', this.isMobile);

    await this.$store.dispatch('getQuiz', {
      id: this.source,
      label: this.defaultLabel,
      steps: this.defaultSteps
    });

    this.addCustomSteps();
  },
  mounted() {
    window.emit.emit('quiz-init');

    [].forEach.call(document.querySelectorAll('.js-quiz-toggle'), elem => elem.classList.toggle('hidden'));
  },
  methods: {
    addCustomSteps() {
      const customSteps = this.defaultCustomSteps();
      this.$store.commit('addCustomSteps', customSteps);
    },

    defaultCustomSteps() {
      const customSteps = [];

      // if (this.stepPrice) {
      //   customSteps.push({
      //     name: 'priceRadio',
      //     component: 'price-form-radio',
      //     heading: 'На какой бюджет вы рассчитываете?'
      //   });
      // }

      customSteps.push({
        name: 'finish',
        component: 'send-form'
      });

      return customSteps;
    }
  }
}
</script>
