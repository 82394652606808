import Vue from 'vue' //'vue/dist/vue.js'
import ru from 'vee-validate/dist/locale/ru'
import VeeValidate, { Validator } from 'vee-validate'
import * as uiv from 'uiv'

import './directives'


import Quiz from './modules/quiz/App.vue'
import QuizLanding from './modules/quiz-landing/App'

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.use(uiv, { prefix: 'bs' });

Validator.localize('ru', ru);

// Modules
Vue.component('quiz', Quiz);
Vue.component('quiz-landing', QuizLanding);

Vue.mixin({
  methods: {
    ya(code) {
      const id = document.querySelector('[ya-counter]');

      if (id) {
        const name = 'yaCounter' + id.getAttribute('ya-counter');

        if (window.hasOwnProperty(name)) {
          window[name].reachGoal(code);
        }
      }
    }
  }
});

const handle = element => {
  if (element && !element.classList.contains('vue-completed')) {
    element.classList.add('vue-completed');

    new Vue({
      el: element
    });
  }
};

const app = () =>
[].forEach.call(document.querySelectorAll('.vue-init:not(.vue-completed)'), element => handle(element));

window.initVue = window.mortgageVue = window.chartPunctuialityCompany = app;
window.renderVue = handle;
window.renderVueAgain = element => {
  element.classList.remove('vue-completed');
  handle(element);
};

(function(w, d, s, h, id) {
  w.roistatProjectId = id; w.roistatHost = h;
  var p = d.location.protocol == "https:" ? "https://" : "http://";
  var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
  var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);

  let name = "roistat_visit";
  let value = id;

  document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  console.log(id)
})(window, document, 'script', 'cloud.roistat.com', '3582b1c96d3c925682d4b56de44a2f77');

app();
