<template>
    <div class="listing__items listing__container">
      <template v-if="list">
        <fragment v-for="build in list" :key="build.id">
          <build-item :build="build"></build-item>
        </fragment>
      </template>
      <template v-else-if="errors.items.length > 0">
        <div class="text_lg">Произошла ошибка. Попробуйте обновить страницу</div>
      </template>
      <template v-else>
        <div class="text_lg">Идет поиск ...</div>
      </template>

    </div>
</template>

<script>
  import BuildItem from '@/modules/quiz-landing/components/BuildItemComponent'
  import { Fragment } from 'vue-fragment'

  export default {
    props: ['list'],
    components: {
      'build-item': BuildItem,
      'Fragment': Fragment
    }
  }
</script>
