<template>
  <a :href="build.alias" class="list-item-wrap">
    <div class="list-item">
      <div class="list-item__img">
        <img :src="build.image" alt="" class="card__image">
      </div>
      <div class="list-item__header">
        <div class="list-item__title">{{build.name}}</div>
        <div class="list-item__addr">{{build.address}}</div>
      </div>
    </div>
  </a>
</template>
<style lang="scss">
.list-item {
  border: 1px solid #E6E6E6;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.05);
  border-radius: 0;
  padding: 15px 15px 0;
  color: #2B2D36;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  background: #fff;
  
  &-wrap {
    width: 33.33%;
    padding: 10px;
    @media(max-width: 768px) {
      width: 50%;
    }
    @media(max-width: 650px) {
      width: 100%;
      padding: 10px 0;
    }
  }
  &__img {
    overflow: hidden;
    height: 147px;
    margin-bottom: 15px;
    img {
      min-height: 147px;
      min-width: 100%;
    }
  }
  
  &__header {
    padding-bottom: 16px;
    flex: 1 1 auto;
  }
  
  &__title {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 4px;
  }
  
  &__addr {
    padding-bottom: 2px;
  }
  
  &__footer {
    border-top: 1px solid #E6E6E6;
    padding-top: 16px;
    font-size: 16px;
    
  }
  
  &__price {
    font-weight: 700;
  }
  
  &__counts {
  
  }
  
  .list-item__go {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    
    li {
      display: flex;
      margin-right: 3px;
      align-items: center;
    }
    
    .metro-icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      margin-right: 8px;
      position: relative;
      top: -2px;
    }
    
    svg {
      position: relative;
      top: -2px;
    }
  }
}
</style>
<script>
import { TextHelper } from '../../../helpers/TextHelper';

export default {
  props: ['build'],
  methods: {
    showAdsCount(data){
      if(data){
        return data + ' ' + TextHelper.declensionWords(data,['квартира','квартиры','квартир']) + ' в продаже'
      }
    },
    price(data) {
      if (data.min_price == data.max_price) {
        return Number(parseFloat(Math.round(data.min_price)).toFixed(1)).toLocaleString() + ' руб.';
      } else {
        return Number(parseFloat(Math.round(data.min_price)).toFixed(1)).toLocaleString() + ' - ' + Number(parseFloat(Math.round(data.max_price)).toFixed(1)).toLocaleString() + ' руб.';
      }
    }
  }
}
</script>
